<template>
  <form v-on:submit.prevent="onSubmit">
    <loading :active.sync="isPageLoading" is-full-page></loading>

    <div class="modal-header">
      <h4 class="modal-title">{{action}} Address Book</h4>
    </div>

    <div class="modal-body">
      <div class="form-group">
        <label for="addressBookName">Name <span class="red-dot ml-3"></span></label>
        <input id="addressBookName" name="addressBookName" type="text" class="form-control" :class="{ 'is-invalid': errors.has('addressBookName') }"
               autocomplete="off" placeholder="Name" v-model="addressBook.name" v-validate="'required'" data-vv-as="Name" />
        <div class="invalid-feedback">The Name field is required.</div>
      </div>

      <div class="form-group" v-if="!isEdit">
        <p>
          <strong>Please use this document as a template for submitting your contacts:</strong> <a href="/documents/addressbooktemplate.xlsx">Address Book Contact List Template</a>
        </p>

        <label for="contacts">Contacts <span class="red-dot ml-3"></span></label>

        <UploadFile id="Contacts"
                    :existingFile.sync="addressBook.contactsFile"
                    @saveFile="saveContacts"
                    type="document"
                    :acceptTypes="['xls','xlsx']"
                    :required="true">
        </UploadFile>


      </div>
      <div class="form-group" v-else>

        <data-table :data="searchResults.results"
                    :columns="columns"
                    :query="searchCriteria"
                    :total="searchResults.total"
                    :isLoading="isDataLoading">
          <template v-slot:companyName="{ row }">
            {{ row.companyName }}
          </template>
          <template v-slot:contactName="{ row }">
            {{ row.contactName }}
          </template>
          <template v-slot:email="{ row }">
            {{ row.email }}
          </template>
          <template v-slot:division="{ row }">
            {{ row.division }}
          </template>
          <template v-slot:section="{ row }">
            {{ row.section }}
          </template>
          <template v-slot:address="{ row }">
            {{ row.address }}
          </template>
          <template v-slot:city="{ row }">
            {{ row.city }}
          </template>
          <template v-slot:province="{ row }">
            {{ row.province }}
          </template>
          <template v-slot:postalCode="{ row }">
            {{ row.postalCode }}
          </template>
          <template v-slot:phone="{ row }">
            {{ row.phone }}
          </template>
          <template v-slot:phoneExtension="{ row }">
            {{ row.phoneExtension }}
          </template>
          <template v-slot:fax="{ row }">
            {{ row.fax }}
          </template>
          <template v-slot:loading>
            <loading :active.sync="isDataLoading" :is-full-page="false"></loading>
          </template>
          <template v-slot:no-records>
            <div class="alert alert-warning" role="alert">No records found.</div>
          </template>
        </data-table>

        <div class="mt-5">
          If you would like to edit the contact list for this address book, please delete this address book and create a new one with the updated contact list.
        </div>

      </div>
    </div>

    <div class="alert alert-danger" v-if="errorFromServer.length > 0">
      {{errorFromServer}}
    </div>

    <div class="modal-footer">
      <button type="submit" class="btn btn-success">Save</button>
      <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
    </div>
  </form>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import Toast from "@/utils/toast";
  import ShowError from "@/utils/errorMessage";
  import { DataTable } from "@/components/datatable/";
  import { AddressBookContactService } from "@/services";
  import UploadFile from "@/components/upload-file.vue";

  const defaultSearchCriteria = {
    page: 1,
    pageSize: 10,
    sortOrder: null,
    sortDirection: null
  };

  export default {
    name: 'address-book',
    components: {
      UploadFile,
      DataTable
    },
    watch: {
      "searchCriteria.page": function (newValue, oldValue) {
        if (typeof oldValue !== 'undefined' && newValue !== oldValue) {
          this.getSearchResults();
        }
      },
      "searchCriteria.pageSize": function (newValue, oldValue) {
        if (typeof oldValue !== 'undefined' && newValue !== oldValue) {
          this.getSearchResults();
        }
      },
      "searchCriteria.sortDirection": function (newValue, oldValue) {
        if (typeof oldValue !== 'undefined' && newValue !== oldValue) {
          this.getSearchResults();
        }
      },
      "searchCriteria.sortOrder": function (newValue, oldValue) {
        if (typeof oldValue !== 'undefined' && newValue !== oldValue) {
          this.getSearchResults();
        }
      }
    },
    data: function () {
      return {
        isEdit: false,
        isPageLoading: false,
        errorFromServer: "",
        searchCriteria: {},
        searchResults: { total: 0, results: [] },
        addressBook: {
          name: null,
          contacts: [],
          contactsFile: null
        },
        columns: [
          { display: "Company Name", field: "companyName", exportFieldName: "companyName", sortable: true },
          { display: "Contact Name", field: "contactName", exportFieldName: "contactName", sortable: true },
          { display: "Email Address", field: "email", exportFieldName: "email", sortable: true },
          { display: "Division", field: "division", exportFieldName: "division", sortable: true },
          { display: "Section", field: "section", exportFieldName: "section", sortable: true },
          { display: "Address", field: "address", exportFieldName: "address", sortable: true },
          { display: "City/Town", field: "city", exportFieldName: "city", sortable: true },
          { display: "Province", field: "province", exportFieldName: "province", sortable: true },
          { display: "Postal Code", field: "postalCode", exportFieldName: "postalCode", sortable: true },
          { display: "Phone", field: "phone", exportFieldName: "phone", sortable: true },
          { display: "Phone Extension", field: "phoneExtension", exportFieldName: "phoneExtension", sortable: true },
          { display: "Fax", field: "fax", exportFieldName: "fax", sortable: true }
        ],
        isDataLoading: true
      }
    },
    computed: {
      ...mapGetters(["getAddressBook"]),
      action() {
        return this.isEdit ? "Edit" : "Add";
      }
    },
    mounted: function () {
      this.addressBook = { ...this.getAddressBook };
      this.isEdit = !!this.addressBook.id;
      this.$validator.reset();

      if (this.isEdit) {
        this.searchCriteria = { ...defaultSearchCriteria };
        this.getSearchResults();
      }
    },
    methods: {
      getSearchResults() {
        AddressBookContactService.search({ addressBookId: this.addressBook.id, ...this.searchCriteria }).then(response => {
          this.searchResults = response.data;
          this.isDataLoading = false;
        });
      },
      async onSubmit() {
        var isFormValid = await this.$validator.validate();

        if (!isFormValid) {
          return;
        }

        this.isPageLoading = true;

        this.saveAddressBook({ addressBook: this.addressBook, isEdit: this.isEdit })
          .then(() => {
            Toast.success(this, "Successfully saved!");
            this.$bvModal.hide("bv-modal-address-book");
            this.closeModal();
          })
          .catch((err) => {
            this.isPageLoading = false;

            if (err.response && err.response.data.model && err.response.data.model.errors) {
              this.errorFromServer = err.response.data.model.errors[0].errorMessage;
            }
            else {
              ShowError.apply(this, err);
              this.closeModal();
            }
          });
      },
      closeModal() {
        this.$bvModal.hide("bv-modal-address-book");
      },
      saveContacts(uploadedFile) {
        this.addressBook.contactsFile = uploadedFile;
      },
      ...mapActions(["saveAddressBook"])
    }
  }
</script>
