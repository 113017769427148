var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('loading',{attrs:{"active":_vm.isPageLoading,"is-full-page":""},on:{"update:active":function($event){_vm.isPageLoading=$event}}}),_c('div',{staticClass:"modal-header"},[_c('h4',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.action)+" Address Book")])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"form-group"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.addressBook.name),expression:"addressBook.name"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control",class:{ 'is-invalid': _vm.errors.has('addressBookName') },attrs:{"id":"addressBookName","name":"addressBookName","type":"text","autocomplete":"off","placeholder":"Name","data-vv-as":"Name"},domProps:{"value":(_vm.addressBook.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.addressBook, "name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v("The Name field is required.")])]),(!_vm.isEdit)?_c('div',{staticClass:"form-group"},[_vm._m(1),_vm._m(2),_c('UploadFile',{attrs:{"id":"Contacts","existingFile":_vm.addressBook.contactsFile,"type":"document","acceptTypes":['xls','xlsx'],"required":true},on:{"update:existingFile":function($event){return _vm.$set(_vm.addressBook, "contactsFile", $event)},"update:existing-file":function($event){return _vm.$set(_vm.addressBook, "contactsFile", $event)},"saveFile":_vm.saveContacts}})],1):_c('div',{staticClass:"form-group"},[_c('data-table',{attrs:{"data":_vm.searchResults.results,"columns":_vm.columns,"query":_vm.searchCriteria,"total":_vm.searchResults.total,"isLoading":_vm.isDataLoading},scopedSlots:_vm._u([{key:"companyName",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.companyName)+" ")]}},{key:"contactName",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.contactName)+" ")]}},{key:"email",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.email)+" ")]}},{key:"division",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.division)+" ")]}},{key:"section",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.section)+" ")]}},{key:"address",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.address)+" ")]}},{key:"city",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.city)+" ")]}},{key:"province",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.province)+" ")]}},{key:"postalCode",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.postalCode)+" ")]}},{key:"phone",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.phone)+" ")]}},{key:"phoneExtension",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.phoneExtension)+" ")]}},{key:"fax",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.fax)+" ")]}},{key:"loading",fn:function(){return [_c('loading',{attrs:{"active":_vm.isDataLoading,"is-full-page":false},on:{"update:active":function($event){_vm.isDataLoading=$event}}})]},proxy:true},{key:"no-records",fn:function(){return [_c('div',{staticClass:"alert alert-warning",attrs:{"role":"alert"}},[_vm._v("No records found.")])]},proxy:true}])}),_c('div',{staticClass:"mt-5"},[_vm._v(" If you would like to edit the contact list for this address book, please delete this address book and create a new one with the updated contact list. ")])],1)]),(_vm.errorFromServer.length > 0)?_c('div',{staticClass:"alert alert-danger"},[_vm._v(" "+_vm._s(_vm.errorFromServer)+" ")]):_vm._e(),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-success",attrs:{"type":"submit"}},[_vm._v("Save")]),_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_vm._v("Close")])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{attrs:{"for":"addressBookName"}},[_vm._v("Name "),_c('span',{staticClass:"red-dot ml-3"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('strong',[_vm._v("Please use this document as a template for submitting your contacts:")]),_vm._v(" "),_c('a',{attrs:{"href":"/documents/addressbooktemplate.xlsx"}},[_vm._v("Address Book Contact List Template")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{attrs:{"for":"contacts"}},[_vm._v("Contacts "),_c('span',{staticClass:"red-dot ml-3"})])}]

export { render, staticRenderFns }